
import React from "react";
import {Route} from "react-router-dom";
import Index from "./pages/index";
export const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
        if (route.collapse) {
            return getRoutes(route.collapse);
        }

        if (route.route) {
            return <Route exact path={route.route} element={route.component} key={route.key} />;
        }

        return null;
    });

const routes = () => {
    let r = [

        {
            key: "index",
            route: "/",
            component: <Index />,
        },

    ];

    return r ;
}

export default routes;
