import Radium from 'radium';
import React from "react";
import {useNavigate} from "react-router-dom";

function Index() {


    return (



    <div>
    {/* <!-- Preloader --> */}
    <div className="preloader-bg"></div>
    <div id="preloader">
        <div id="preloader-status">
            <div className="preloader-position loader"> <span></span> </div>
        </div>
    </div>
    {/* <!-- Progress scroll totop --> */}
    <div className="progress-wrap cursor-pointer">
        <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
    </div>
    {/* <!-- Navbar --> */}
    <nav className="navbar navbar-expand-lg">
        <div className="container">
            {/* <!-- Logo --> */}
            <div className="logo-wrapper">
                <a className="logo" href="index.html"> <img src="./assets/logo/logo.png" alt=""/> </a>
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span className="icon-bar"><i className="ti-menu"></i></span> </button>
            {/* <!-- Navbar links --> */}
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item"><a className="nav-link active" href="#" data-scroll-nav="0">Accueil</a></li>
                    <li className="nav-item"><a className="nav-link" href="#" data-scroll-nav="1">À Propos</a></li>
                    <li className="nav-item"><a className="nav-link" href="#" data-scroll-nav="2">Projets</a></li>
                    <li className="nav-item"><a className="nav-link" href="#" data-scroll-nav="4">Services</a></li>
                    <li className="nav-item"><a className="nav-link" href="#" data-scroll-nav="6">Équipe</a></li>
                    <li className="nav-item"><a className="nav-link" href="#" data-scroll-nav="7">Galerie</a></li>
                    <li className="nav-item"><a className="nav-link" href="#" data-scroll-nav="8">Blog</a></li>
                    <li className="nav-item"><a className="nav-link" href="#" data-scroll-nav="9">Contact</a></li>
                </ul>
            </div>
        </div>
    </nav>
    {/* <!-- Slider --> */}
    <header id="home" className="header slider-fade" data-scroll-index="0">
        <div className="owl-carousel owl-theme">
            {/* <!-- The opacity on the image is made with "data-overlay-dark="number".You can change it using the numbers 0-9. --> */}
            <div className="text-left item bg-img" data-overlay-dark="3" data-background="./assets/images/home/trees-3822149.jpg">
                <div className="v-middle caption">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mt-30">
                                <div className="o-hidden">
                                    <h6>Residental</h6>
                                    <h1>Écologique</h1>
                                    <p>Architecture viverra tellus nec massa dictum the blackspace euismoe.<br/>Curabitur viverra the posuere hose aukue velition.</p>
                                    <a href="project-page.html" className="btn float-btn flat-btn">Discover</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-left item bg-img" data-overlay-dark="3" data-background="./assets/images/home/mountains-6531903.jpg">
                <div className="v-middle caption">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mt-30">
                                <div className="o-hidden">
                                    <h6>Residental</h6>
                                    <h1>Naturel</h1>
                                    <p>Architecture viverra tellus nec massa dictum the blackspace euismoe.<br/>Curabitur viverra the posuere hose aukue velition.</p>
                                    <a href="project-page.html" className="btn float-btn flat-btn">Discover</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-left item bg-img" data-overlay-dark="4" data-background="./assets/images/home/japan-8257601_1920.jpg">
                <div className="v-middle caption">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mt-30">
                                <div className="o-hidden">
                                    <h6>Residental</h6>
                                    <h1>Environnemental</h1>
                                    <p>Architecture viverra tellus nec massa dictum the blackspace euismoe.<br/>Curabitur viverra the posuere hose aukue velition.</p>
                                    <a href="project-page.html" className="btn float-btn flat-btn">Discover</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    {/* <!-- Slider Services Box --> */}
    <div className="slider-services-wrapper">
        <div className="slider-box-content box-up clearfix">
            <div className="row no-gutters">
                <div className="col-md-3 offset-md-6">
                    <div className="slider-services-box b-right b-bottom">
                        <a href="architectural-design.html">
                            <div className="slider-services-box-info">
                                <img src="./savoye/img/icons/6.png" className="services-icon" alt=""/>
                                    <h5>Écologique</h5>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="slider-services-box b-bottom">
                        <a href="interior-design.html">
                            <div className="slider-services-box-info">
                                <img src="./savoye/img/icons/2.png" className="services-icon" alt=""/>
                                    <h5>Nature</h5>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col-12">
                    <hr className="no-line"/>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3">
                    <div className="slider-services-box b-left b-bottom">
                        <a href="urban-design.html">
                            <div className="slider-services-box-info">
                                <img src="./savoye/img/icons/3.png" className="services-icon" alt=""/>
                                    <h5>Bio-Diversité</h5>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="slider-services-box b-left b-right b-bottom">
                        <a href="decor-plan.html">
                            <div className="slider-services-box-info">
                                <img src="./savoye/img/icons/5.png" className="services-icon" alt=""/>
                                    <h5>Étude d'impacts</h5>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- About --> */}
    <div id="about" className="about section-padding" data-scroll-index="1">
        <div className="container">
            <div className="row">
                <div className="col-md-7">
                    <div className="section-title">À Propos de nous</div>
                    <p>Architecture innovation tristiue usto duision vitae diam nemue nivamus aesta atene artin arinian finibus ate viverra nec lacus. Nedana edino suscipe.</p>
                    <p>Savoye inila duman aten elit finibus vivera alacus themone the drudean seneice miuscibe noneten the fermen. Savoye architecture duiman at elit finibus viverra nec a lacus themo drudeane sene voice misuscipit non sagie the volume fermen.</p>
                    <p>Viverra tristique jusio the ivite dianne onen nivam acsestion augue artine. Savoye toverra ristique usto vitae diam nenon sovaye aesta vazio lacus.</p>
                </div>
                <div className="col-md-5">
                    <div className="about-img">
                        <div className="img"> <img src="./savoye/img/about.png" className="img-fluid" alt=""/> </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Projects --> */}
    <div id="projects" className="savoye-project section-padding" data-scroll-index="2">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <div className="section-title">Nos projets</div>
                </div>
            </div>
            <div className="row savoye-project-items">
                <div className="col-md-6 single-item interior">
                    <div className="savoye-project-wrap">
                        <a href="project-page.html"><img src="./assets/images/home/eibsee-7352987_640.jpg" alt=""/></a>
                        <div className="savoye-project-content">
                            <p>Architecture</p>
                            <h3><a href="project-page.html">Bungalow Dark House</a></h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 single-item exterior">
                    <div className="savoye-project-wrap">
                        <a href="project-page.html"><img src="./assets/images/home/lake-6641880.jpg" alt=""/></a>
                        <div className="savoye-project-content">
                            <p>Architecture</p>
                            <h3><a href="project-page.html">Woodenist House<br/>Lumberjack</a></h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 single-item interior">
                    <div className="savoye-project-wrap">
                        <a href="project-page.html"><img src="./savoye/img/projects/3.jpg" alt=""/></a>
                        <div className="savoye-project-content">
                            <p>Exterior Design</p>
                            <h3><a href="project-page.html">Geometric Building</a></h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 single-item exterior">
                    <div className="savoye-project-wrap">
                        <a href="project-page.html"><img src="./savoye/img/projects/4.jpg" alt=""/></a>
                        <div className="savoye-project-content">
                            <p>Architecture</p>
                            <h3><a href="project-page.html">Arch Cloud Honna<br/>Didenton Villa</a></h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 single-item interior">
                    <div className="savoye-project-wrap">
                        <a href="project-page.html"><img src="./savoye/img/projects/5.jpg" alt=""/></a>
                        <div className="savoye-project-content">
                            <p>Architecture</p>
                            <h3><a href="project-page.html">Brown Monara House<br/>Ottova Canada</a></h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 single-item exterior">
                    <div className="savoye-project-wrap">
                        <a href="project-page.html"><img src="./assets/images/home/lake-6641880.jpg" alt=""/></a>
                        <div className="savoye-project-content">
                            <p>Architecture</p>
                            <h3><a href="project-page.html">Twin Forestland Home</a></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Parallax Section --> */}
    <div id="parallax-section" className="parallax-header parallax-slider-fade" data-scroll-index="3">
        <div className="owl-carousel owl-theme">
            {/* <!-- The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9. --> */}
            <div className="text-left item bg-img" data-overlay-dark="3" data-background="./assets/images/home/lake-6641880.jpg">
                <div className="v-middle caption">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="o-hidden">
                                    <h6>Discover</h6>
                                    <h2>Ecologie</h2>
                                    <p><a href="project-page.html"><i className="ti-location-pin"></i> North Branch, NY, United States</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-left item bg-img" data-overlay-dark="3" data-background="./assets/images/home/japan-8257601_1920.jpg">
                <div className="v-middle caption">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="o-hidden">
                                    <h6>Discover</h6>
                                    <h2>Nature</h2>
                                    <p><a href="project-page.html"><i className="ti-location-pin"></i> Princeton, NJ, United States</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-left item bg-img" data-overlay-dark="4" data-background="./assets/images/home/mountains-6531903.jpg">
                <div className="v-middle caption">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="o-hidden">
                                    <h6>Discover</h6>
                                    <h2>Diversité</h2>
                                    <p><a href="project-page.html"><i className="ti-location-pin"></i> Ashburn, VA, United States</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Services --> */}
    <div id="services" className="services-feat section-padding" data-scroll-index="4">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <div className="section-title">Nos services</div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="owl-carousel owl-theme">
                        <div className="square-flip">
                            <div className="square bg-img" data-background="./assets/images/home/mountains-6531903.jpg">
                                <div className="square-container d-flex align-items-end justify-content-end">
                                    <div className="box-title text-vertical">
                                        <h4>Environnement</h4>
                                    </div>
                                </div>
                                <div className="flip-overlay"></div>
                            </div>
                            <div className="square2">
                                <div className="square-container2">
                                    <h4>Architectural Design</h4>
                                    <p>Architecture viverra tristique ustoni missten vitae diam neque nivamus aestan the atene artines arinianu ateli ine finibus viverra nec lacus. Nedana theme sea no curabit.</p>
                                    <div className="btn-line"><a href="architectural-design.html">Read more</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="square-flip">
                            <div className="square bg-img" data-background="./assets/images/home/trees-3822149.jpg">
                                <div className="square-container d-flex align-items-end justify-content-end">
                                    <div className="box-title text-vertical">
                                        <h4>Ecologie</h4>
                                    </div>
                                </div>
                                <div className="flip-overlay"></div>
                            </div>
                            <div className="square2">
                                <div className="square-container2">
                                    <h4>Interior Design</h4>
                                    <p>Architecture viverra tristique ustoni missten vitae diam neque nivamus aestan the atene artines arinianu ateli ine finibus viverra nec lacus. Nedana theme sea no curabit.</p>
                                    <div className="btn-line"><a href="interior-design.html">Read more</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="square-flip">
                            <div className="square bg-img" data-background="./assets/images/home/eibsee-7352987_640.jpg">
                                <div className="square-container d-flex align-items-end justify-content-end">
                                    <div className="box-title text-vertical">
                                        <h4>Nature</h4>
                                    </div>
                                </div>
                                <div className="flip-overlay"></div>
                            </div>
                            <div className="square2">
                                <div className="square-container2">
                                    <h4>Nature</h4>
                                    <p>Architecture viverra tristique ustoni missten vitae diam neque nivamus aestan the atene artines arinianu ateli ine finibus viverra nec lacus. Nedana theme sea no curabit.</p>
                                    <div className="btn-line"><a href="urban-design.html">Read more</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="square-flip">
                            <div className="square bg-img" data-background="./savoye/img/services/4.jpg">
                                <div className="square-container d-flex align-items-end justify-content-end">
                                    <div className="box-title text-vertical">
                                        <h4>3D Modelling</h4>
                                    </div>
                                </div>
                                <div className="flip-overlay"></div>
                            </div>
                            <div className="square2">
                                <div className="square-container2">
                                    <h4>3D Modelling</h4>
                                    <p>Architecture viverra tristique ustoni missten vitae diam neque nivamus aestan the atene artines arinianu ateli ine finibus viverra nec lacus. Nedana theme sea no curabit.</p>
                                    <div className="btn-line"><a href="3d-modelling.html">Read more</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="square-flip">
                            <div className="square bg-img" data-background="./savoye/img/services/5.jpg">
                                <div className="square-container d-flex align-items-end justify-content-end">
                                    <div className="box-title text-vertical">
                                        <h4>Decor Plan</h4>
                                    </div>
                                </div>
                                <div className="flip-overlay"></div>
                            </div>
                            <div className="square2">
                                <div className="square-container2">
                                    <h4>Decor Plan</h4>
                                    <p>Architecture viverra tristique ustoni missten vitae diam neque nivamus aestan the atene artines arinianu ateli ine finibus viverra nec lacus. Nedana theme sea no curabit.</p>
                                    <div className="btn-line"><a href="decor-plan.html">Read more</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="square-flip">
                            <div className="square bg-img" data-background="./savoye/img/services/6.jpg">
                                <div className="square-container d-flex align-items-end justify-content-end">
                                    <div className="box-title text-vertical">
                                        <h4>Planning</h4>
                                    </div>
                                </div>
                                <div className="flip-overlay"></div>
                            </div>
                            <div className="square2">
                                <div className="square-container2">
                                    <h4>Planning</h4>
                                    <p>Architecture viverra tristique ustoni missten vitae diam neque nivamus aestan the atene artines arinianu ateli ine finibus viverra nec lacus. Nedana theme sea no curabit.</p>
                                    <div className="btn-line"><a href="planning.html">Read more</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Testiominals --> */}
    <div id="testimonials" className="testimonials section-padding bg-img bg-fixed" data-overlay-dark="5" data-background="./savoye/img/slider/2.jpg">
        <div className="container">
            <div className="row">
                <div className="owl-carousel owl-theme col-md-12">
                    <div className="item-box"><span className="quote"><img src="./savoye/img/quot.png" alt=""/></span>
                        <p>"Sovaye tristique usto duision vitae diamen neque nivamus aesta atene artines arinianu ateli finibus viverra nec lacus. Nedana dinoz setlie suscipe none misster cuvvan tristique. Developer inila duman aten elit finibus viveracus themone the dru."</p>
                        <div className="info">
                            <div className="author-img"> <img src="./savoye/img/team/2.jpg" alt=""/> </div>
                            <div className="cont">
                                <h6>Olivia Brown</h6> <span>Manager at HMM Arch.</span>
                            </div>
                        </div>
                    </div>
                    <div className="item-box"> <span className="quote"><img src="./savoye/img/quot.png" alt=""/></span>
                        <p>"Sovaye tristique usto duision vitae diamen neque nivamus aesta atene artines arinianu ateli finibus viverra nec lacus. Nedana dinoz setlie suscipe none misster cuvvan tristique. Developer inila duman aten elit finibus viveracus themone the dru."</p>
                        <div className="info">
                            <div className="author-img"> <img src="./savoye/img/team/1.jpg" alt=""/> </div>
                            <div className="cont">
                                <h6>Tammy White</h6> <span>Director at NoArch</span>
                            </div>
                        </div>
                    </div>
                    <div className="item-box"> <span className="quote"><img src="./savoye/img/quot.png" alt=""/></span>
                        <p>"Sovaye tristique usto duision vitae diamen neque nivamus aesta atene artines arinianu ateli finibus viverra nec lacus. Nedana dinoz setlie suscipe none misster cuvvan tristique. Developer inila duman aten elit finibus viveracus themone the dru."</p>
                        <div className="info">
                            <div className="author-img"> <img src="./savoye/img/team/3.jpg" alt=""/> </div>
                            <div className="cont">
                                <h6>Martin Brown</h6> <span>Architect at Baeun Arch.</span>
                            </div>
                        </div>
                    </div>
                    <div className="item-box"> <span className="quote"><img src="./savoye/img/quot.png" alt=""/></span>
                        <p>"Sovaye tristique usto duision vitae diamen neque nivamus aesta atene artines arinianu ateli finibus viverra nec lacus. Nedana dinoz setlie suscipe none misster cuvvan tristique. Developer inila duman aten elit finibus viveracus themone the dru."</p>
                        <div className="info">
                            <div className="author-img"> <img src="./savoye/img/team/6.jpg" alt=""/> </div>
                            <div className="cont">
                                <h6>Betty Dan</h6> <span>Director at Titanic Firm</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Team --> */}
    <div id="team" className="team section-padding" data-scroll-index="6">
        <div className="container">
            <div className="row">
                <div className="col-md-12 mb-15">
                    <div className="section-title">Notre équipe</div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="item toright mb-30">
                        <div className="img"> <img src="./savoye/img/team/1.jpg" alt=""/> </div>
                        <div className="info valign">
                            <div className="full-width">
                                <h6>Robert Brown</h6> / <span>dipl. Arch ETH/SIA</span>
                                <p>Viverra tristique usto duis vitae diam neque sivamus aesta vazio artines aten.</p>
                                <div className="social">
                                    <div className="full-width">
                                        <a href="#0" className="icon"> <i className="ti-facebook"></i> </a>
                                        <a href="#0" className="icon"> <i className="ti-twitter"></i> </a>
                                        <a href="#0" className="icon"> <i className="ti-linkedin"></i> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="item mb-30">
                        <div className="img"> <img src="./savoye/img/team/2.jpg" alt=""/> </div>
                        <div className="info valign">
                            <div className="full-width">
                                <h6>Emily Emma</h6> / <span>dipl. Arch ETH/SIA</span>
                                <p>Viverra tristique usto duis vitae diam neque sivamus aesta vazio artines aten.</p>
                                <div className="social">
                                    <div className="full-width">
                                        <a href="#0" className="icon"> <i className="ti-facebook"></i> </a>
                                        <a href="#0" className="icon"> <i className="ti-twitter"></i> </a>
                                        <a href="#0" className="icon"> <i className="ti-linkedin"></i> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="item toright mb-30">
                        <div className="img"> <img src="./savoye/img/team/3.jpg" alt=""/> </div>
                        <div className="info valign">
                            <div className="full-width">
                                <h6>Benjamin Ava</h6> / <span>dipl. Arch ETH/SIA</span>
                                <p>Viverra tristique usto duis vitae diam neque sivamus aesta vazio artines aten.</p>
                                <div className="social">
                                    <div className="full-width">
                                        <a href="#0" className="icon"> <i className="ti-facebook"></i> </a>
                                        <a href="#0" className="icon"> <i className="ti-twitter"></i> </a>
                                        <a href="#0" className="icon"> <i className="ti-linkedin"></i> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="item mb-30">
                        <div className="img"> <img src="./savoye/img/team/4.jpg" alt=""/> </div>
                        <div className="info valign">
                            <div className="full-width">
                                <h6>Sophia Miane</h6> / <span>dipl. Arch ETH/SIA</span>
                                <p>Viverra tristique usto duis vitae diam neque sivamus aesta vazio artines aten.</p>
                                <div className="social">
                                    <div className="full-width">
                                        <a href="#0" className="icon"> <i className="ti-facebook"></i> </a>
                                        <a href="#0" className="icon"> <i className="ti-twitter"></i> </a>
                                        <a href="#0" className="icon"> <i className="ti-linkedin"></i> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Image Gallery --> */}
    <div id="gallery" className="section-padding" data-scroll-index="7">
        <div className="container">
            <div className="row">
                <div className="col-md-12 mb-15">
                    <div className="section-title">Portfolio</div>
                </div>
            </div>
            <div className="row savoye-photos">
                <div className="col-12 col-md-4 masonry-items">
                    <div className="portfolio-item-wrapp">
                        <div className="portfolio-item">
                            <div className="gallery-masonry-wrapper">
                                <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="./savoye/img/projects/1.jpg" data-caption="Alvar Alto Museum"> <img className="img-fluid" src="./savoye/img/projects/1.jpg" alt=""/>
                                    <div className="gallery-masonry-item-img"></div>
                                    <div className="gallery-masonry-item-content">
                                        <h4 className="gallery-masonry-item-title">Alvar Alto Museum</h4>
                                        <div className="gallery-masonry-item-category">Casablanca, Morocco</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-item-wrapp">
                        <div className="portfolio-item">
                            <div className="gallery-masonry-wrapper">
                                <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="./savoye/img/projects/4.jpg" data-caption="European Lard Station"> <img className="img-fluid" src="./savoye/img/projects/4.jpg" alt=""/>
                                    <div className="gallery-masonry-item-img"></div>
                                    <div className="gallery-masonry-item-content">
                                        <h4 className="gallery-masonry-item-title">European Lard Station</h4>
                                        <div className="gallery-masonry-item-category">Strasbourg, France</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-item-wrapp">
                        <div className="portfolio-item">
                            <div className="gallery-masonry-wrapper">
                                <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="./savoye/img/projects/2.jpg" data-caption="Yabroudi Villa"> <img className="img-fluid" src="./savoye/img/projects/2.jpg" alt=""/>
                                    <div className="gallery-masonry-item-img"></div>
                                    <div className="gallery-masonry-item-content">
                                        <h4 className="gallery-masonry-item-title">Yabroudi Villa</h4>
                                        <div className="gallery-masonry-item-category">Dubai, United Arab Emirates</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 masonry-items">
                    <div className="portfolio-item-wrapp">
                        <div className="portfolio-item">
                            <div className="gallery-masonry-wrapper">
                                <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="./savoye/img/projects/2.jpg" data-caption="Cultural Complex Centre"> <img className="img-fluid" src="./savoye/img/projects/2.jpg" alt=""/>
                                    <div className="gallery-masonry-item-img"></div>
                                    <div className="gallery-masonry-item-content">
                                        <h4 className="gallery-masonry-item-title">Cultural Centre</h4>
                                        <div className="gallery-masonry-item-category">Muscat, Sultanate of Oman</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-item-wrapp">
                        <div className="portfolio-item">
                            <div className="gallery-masonry-wrapper">
                                <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="./savoye/img/projects/5.jpg" data-caption="Dalbourne Villa"> <img className="img-fluid" src="./savoye/img/projects/5.jpg" alt=""/>
                                    <div className="gallery-masonry-item-img"></div>
                                    <div className="gallery-masonry-item-content">
                                        <h4 className="gallery-masonry-item-title">Dalbourne Villa</h4>
                                        <div className="gallery-masonry-item-category">Guangzhou, China</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-item-wrapp">
                        <div className="portfolio-item">
                            <div className="gallery-masonry-wrapper">
                                <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="./savoye/img/projects/8.jpg" data-caption="Amman Rotana Hotel"> <img className="img-fluid" src="./savoye/img/projects/8.jpg" alt=""/>
                                    <div className="gallery-masonry-item-img"></div>
                                    <div className="gallery-masonry-item-content">
                                        <h4 className="gallery-masonry-item-title">Amman Rotana Hotel</h4>
                                        <div className="gallery-masonry-item-category">Amman, Jordan</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 masonry-items">
                    <div className="portfolio-item-wrapp">
                        <div className="portfolio-item">
                            <div className="gallery-masonry-wrapper">
                                <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="./savoye/img/projects/3.jpg" data-caption="Alvar Alto Museum"> <img className="img-fluid" src="./savoye/img/projects/3.jpg" alt=""/>
                                    <div className="gallery-masonry-item-img"></div>
                                    <div className="gallery-masonry-item-content">
                                        <h4 className="gallery-masonry-item-title">Alvar Alto Museum</h4>
                                        <div className="gallery-masonry-item-category">Casablanca, Morocco</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-item-wrapp">
                        <div className="portfolio-item">
                            <div className="gallery-masonry-wrapper">
                                <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="./savoye/img/projects/6.jpg" data-caption="Dance and Music Theatre"> <img className="img-fluid" src="./savoye/img/projects/6.jpg" alt=""/>
                                    <div className="gallery-masonry-item-img"></div>
                                    <div className="gallery-masonry-item-content">
                                        <h4 className="gallery-masonry-item-title">Dance The Theatre</h4>
                                        <div className="gallery-masonry-item-category">Perth, Australia</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-item-wrapp">
                        <div className="portfolio-item">
                            <div className="gallery-masonry-wrapper">
                                <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="./savoye/img/projects/7.jpg" data-caption="French Embassy"> <img className="img-fluid" src="./savoye/img/projects/7.jpg" alt=""/>
                                    <div className="gallery-masonry-item-img"></div>
                                    <div className="gallery-masonry-item-content">
                                        <h4 className="gallery-masonry-item-title">French Embassy</h4>
                                        <div className="gallery-masonry-item-category">Casablanca, Morocco</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Blog --> */}
    <div id="blog" className="blog section-padding" data-scroll-index="8">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="section-title">Dernières Actus</div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="item">
                        <div className="post-img">
                            <a href="post.html"><div className="img"> <img src="./savoye/img/blog/5.jpg" alt=""/> </div></a>
                        </div>
                        <div className="cont">
                            <h4><a href="post.html">A Brief History on the Beginnings of Modern Architecture</a></h4>
                            <div className="info"> <a href="blog.html"><span>Event</span></a> <a href="blog.html">October, 24</a> </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="item">
                        <div className="post-img">
                            <a href="post.html"><div className="img"> <img src="./savoye/img/blog/6.jpg" alt=""/> </div></a>
                        </div>
                        <div className="cont">
                            <h4><a href="post.html">Luxurious And Ultra Modern Homes In The World</a></h4>
                            <div className="info"> <a href="blog.html"><span>Event</span></a> <a href="blog.html">October, 21</a> </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="item">
                        <div className="post-img">
                            <a href="post.html"><div className="img"> <img src="./savoye/img/blog/7.jpg" alt=""/> </div></a>
                        </div>
                        <div className="cont">
                            <h4><a href="post.html">This Ultra-Modern Mountain Home Goes Above and Beyond</a></h4>
                            <div className="info"> <a href="blog.html"><span>Event</span></a> <a href="blog.html">October, 18</a> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- hr --> */}
    <hr className="line-vr-section"/>
        {/* <!-- Top Footer Banner --> */}
        <div id="contact" className="topbanner-footer" data-scroll-index="9">
            <div className="section-padding banner-img valign bg-img bg-fixed" data-overlay-light="4" data-background="./savoye/img/slider/1.jpg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-30 text-left caption">
                            <div className="section-title">Contactez-nous</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <h6>Phone</h6>
                            <h5 className="mb-30">+1 123-456-0606</h5>
                            <p className="mb-30">Monday - Friday: 8am - 6pm<br/>Saturday - Sunday: 9am - 3pm</p>
                        </div>
                        <div className="col-md-4">
                            <h6>Email</h6>
                            <h5 className="mb-30">info@architecture.com</h5>
                            <p className="mb-30">24 King St, Charleston<br/>SC 29401 USA</p>
                        </div>
                        <div className="col-md-3 offset-md-1">
                            <div className="vid-area">
                                <a className="play-button gallery-masonry-item-img-link d-block" data-type="iframe" data-fancybox="iframe" href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3354.758017847153!2d-79.93420398486563!3d32.77215479154045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fe7a1ae84ff639%3A0xe5c782f71924a526!2s24%20King%20St%2C%20Charleston%2C%20SC%2029401%2C%20USA!5e0!3m2!1sen!2str!4v1631170502143!5m2!1sen!2str">
                                    <svg className="circle-fill">
                                        <circle cx="43" cy="43" r="39" stroke="#272727" stroke-width="1"></circle>
                                    </svg>
                                    <svg className="circle-track">
                                        <circle cx="43" cy="43" r="39" stroke="none" stroke-width="1" fill="none"></circle>
                                    </svg> <span className="polygon"><i className="ti-location-pin"></i></span> </a>
                                <div className="cont mt-20 mb-30">
                                    <h5>Our Location</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Footer --> */}
        <footer className="main-footer dark">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 mb-30">
                        <div className="item abot">
                            <div className="logo mb-15"> <img src="./assets/logo/logo.png" alt=""/> </div>
                            <p>Welcome to our Savoye Architecture agency. Viverra tristique justo in duis vitae diam neque niva.</p>
                            <div className="social-icon"> <a href="index.html"><i className="ti-facebook"></i></a> <a href="index.html"><i className="ti-twitter"></i></a> <a href="index.html"><i className="ti-instagram"></i></a> <a href="index.html"><i className="ti-pinterest"></i></a> </div>
                        </div>
                    </div>
                    <div className="col-md-4 offset-md-1 mb-30">
                        <div className="item usful-links">
                            <div className="fothead">
                                <h6>Our Services</h6>
                            </div>
                            <ul>
                                <li><a href="architectural-design.html">Architectural Design</a></li>
                                <li><a href="interior-design.html">Interior Design</a></li>
                                <li><a href="urban-design.html">Urban Design</a></li>
                                <li><a href="decor-plan.html">Decor Plan</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 mb-30">
                        <div className="item fotcont">
                            <div className="fothead">
                                <h6>Let's talk</h6>
                            </div>
                            <p>Have a project in mind?</p>
                            <p>Let's Build Something Great Together</p>
                            <p>+1 123-456-0606</p>
                            <p>info@architecture.com</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sub-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="text-left">
                                <p>© 2021, SAVOYE. All right reserved.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="text-right-left">
                                <p><a href="#">Terms of use</a> <span>|</span> <a href="#"> Privacy Environmental Policy </a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>


    </div>


    );
}

export default Radium(Index);
